<template>
  <CModal
    :show.sync="isShowPopup"
    :close-on-backdrop="false"
    :color="modalColor"
    size="lg">
    <template #header>
      <h3>
        Hello {{ fullName | toTitleCase }} - Welcome Back to {{ getOrgName }}
      </h3>
      <CButtonClose @click="modalCallBack(false)" class="text-black" />
    </template>
    <template #footer-wrapper>
      <div class="d-flex justify-content-end mr-5 mb-3">
        <CButton
          type="button"
          class="px-4 py-2 btn-primary"
          @click="closeModal()">
          {{ buttonLabel }}
        </CButton>
      </div>
    </template>
    <div>
      <h5 class="text-center text-primary">Exciting News</h5>
      <p class="text-center">
        We've been busy developing Talentfind World.<br />
        A global healthcare community.
      </p>

      <h5 class="text-center text-primary">What's New?</h5>
      <ul class="mb-4">
        <li>
          Early in 2024, we are launching our healthcare marketplace - one place
          for everyone involved in healthcare, a healthcare networking
          community.
        </li>
        <li>
          Keep visiting as we roll out features in the coming months that will
          support you and your healthcare career.
        </li>
        <li>
          Log in to your personalized portal and see our upcoming updates.
        </li>
      </ul>

      <p class="text-center">Your personalized portal enables you to:</p>
      <ul class="">
        <li>Manage and update your profile and documents in a secure place.</li>
        <li>
          View and apply for the latest opportunities worldwide - watch this
          space as we update our job board with partner hospitals from all over
          the world.
        </li>
        <li>View pre-screened opportunities that match your requirements.</li>
        <li>And More!</li>
      </ul>

      <p class="text-center">
        We Welcome you to join us on our exciting new journey
      </p>

      <h4 class="text-center mt-4">Thank you!</h4>
    </div>
  </CModal>
</template>

<script>
import { getLocalOrgDetail } from "@/helpers/helper";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    buttonLabel: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters(["getOrgName"]),
    fullName() {
      return `${this.item?.first_name ?? ""} ${this.item?.sur_name ?? ""}`;
    },
    getOrgName() {
      const { name, path_acronym } = getLocalOrgDetail();
      return name || path_acronym?.toUpperCase();
    },
  },

  methods: {
    closeModal() {
      this.$emit("modalCallBack", false);
    },
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
  },

  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    },
  },
};
</script>
